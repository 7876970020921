"use strict";

class PaymentMethod {
    static formSelector = '#paymentMethodForm';
    static submitBtn = null;
    static selectedPaymentOptionRadio = null;
    static stripe = null;
    static stripeElements = [];
    static stripeMethodsInfo = {};

    static Initialize() {
        const form = $(PaymentMethod.formSelector);
        PaymentMethod.submitBtn = form.find('[type="submit"]');

        form.on('submit', function(event) {
            event.preventDefault();
            if (PaymentMethod.selectedPaymentOptionRadio != null) {
                const selectedPaymentOption = parseInt(PaymentMethod.selectedPaymentOptionRadio.val());
                if (selectedPaymentOption == PAYMENT_METHOD_STRIPE || selectedPaymentOption == PAYMENT_METHOD_KLARNA) {
                    PaymentMethod.stripeFormSubmit(selectedPaymentOption);
                    return false;
                } else if (selectedPaymentOption == PAYMENT_METHOD_METAMASK || selectedPaymentOption == PAYMENT_METHOD_WALLETCONNECT) {
                    UI.buttonStartLoading(PaymentMethod.submitBtn, Lang.General.btnProcessing);
                    Wallet.connectWalletForPayment(selectedPaymentOption).then(function() {
                        App.post(BaseURL + '/shop/checkout/payment', PaymentMethod.formSelector, function(resp) {
                            UI.buttonStopLoading(PaymentMethod.submitBtn);
                            UI.handleJsonResponse(resp);
                        });
                    }).catch(function(error) {
                        UI.buttonStopLoading(PaymentMethod.submitBtn);
                        UI.error(error.message);
                    });
                    return false;
                }
            }
            UI.buttonStartLoading(PaymentMethod.submitBtn, Lang.General.btnProcessing);
            App.post(BaseURL + '/shop/checkout/payment', PaymentMethod.formSelector, function(resp) {
                UI.buttonStopLoading(PaymentMethod.submitBtn);
                UI.handleJsonResponse(resp);
            });
            return false;
        });

        $('[name="paymentMethod"]', form).on('change', function(event) {
            const radio = $(this);
            if (radio.is(':checked')) {
                if (PaymentMethod.selectedPaymentOptionRadio != null) {
                    $(PaymentMethod.selectedPaymentOptionRadio.attr('data-bs-target')).collapse('hide');
                    PaymentMethod.selectedPaymentOptionRadio.attr('aria-expanded', 'false');
                }
                $(PaymentMethod.formSelector).find('[name="paymentIntent"]').val(null);
                const selectedPaymentOption = parseInt(radio.val());
                if (selectedPaymentOption == PAYMENT_METHOD_STRIPE || selectedPaymentOption == PAYMENT_METHOD_KLARNA) {
                    return PaymentMethod.selectStripe(radio, selectedPaymentOption);
                } else {
                    PaymentMethod.setSelectedPaymentOption(radio);
                    if (PaymentMethod.submitBtn.length > 0) {
                        PaymentMethod.submitBtn.attr('disabled', false);
                    }
                }
            }
            return true;
        });
    }

    static setSelectedPaymentOption(radio) {
        $(radio.attr('data-bs-target')).collapse('show');
        radio.attr('aria-expanded', 'true');
        PaymentMethod.selectedPaymentOptionRadio = radio;
    }

    static isStripeMethodMounted(paymentOption) {
        return PaymentMethod.stripeElements[paymentOption] !== undefined;
    }

    static selectStripe(radio, paymentOption) {
        if (PaymentMethod.isStripeMethodMounted(paymentOption)) {
            $(PaymentMethod.formSelector).find('[name="paymentIntent"]').val(PaymentMethod.stripeMethodsInfo[paymentOption].paymentIntent);
            PaymentMethod.setSelectedPaymentOption(radio);
            if (PaymentMethod.submitBtn.length > 0) {
                PaymentMethod.submitBtn.attr('disabled', false);
            }
            return true;
        }
        UI.startLoadingOverlay();
        App.post(BaseURL + '/shop/checkout/stripe', PaymentMethod.formSelector, function(resp) {
            if (resp.response) {
                PaymentMethod.ensureStripeLoaded(resp.publicKey, resp.locale, function() {
                    PaymentMethod.mountStripeElements(paymentOption, resp.clientSecret, resp.billingDetails, function() {
                        PaymentMethod.stripeMethodsInfo[paymentOption] = {
                            paymentIntent: resp.paymentIntent,
                            clientSecret: resp.clientSecret
                        };
                        $(PaymentMethod.formSelector).find('[name="paymentIntent"]').val(resp.paymentIntent);
                        UI.stopLoadingOverlay();
                        if (PaymentMethod.submitBtn.length > 0) {
                            PaymentMethod.submitBtn.attr('disabled', false);
                        }
                    });
                    PaymentMethod.setSelectedPaymentOption(radio);
                });
            } else {
                UI.stopLoadingOverlay();
                UI.handleJsonResponse(resp);
                radio.prop('disabled', true);
                radio.prop('checked', false);
            }
        });
        return true;
    }

    static ensureStripeLoaded(publicKey, locale, callback) {
        if (PaymentMethod.stripe != null) {
            if (callback != undefined)
                callback();
        } else {
            App.cachedScript('https://js.stripe.com/v3/').then(function() {
                PaymentMethod.stripe = Stripe(publicKey, { locale });
                if (callback != undefined)
                    callback();
            });
        }
    }

    static getStripeAppearance() {
        return {
            theme: 'stripe',
            labels: 'floating',
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800'
                }
            ],
            variables: {
                colorPrimary: '#0570de',
                colorBackground: '#ffffff',
                colorText: '#30313d',
                colorDanger: '#df1b41',
                fontFamily: '"Montserrat", system-ui, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                lineHeight: '24px',
                spacingUnit: '4px',
                borderRadius: '4px',
                spacingGridRow: '24px'
            },
            rules: {
                '.Input': {
                    border: '1px solid #999',
                    padding: '12px 14px'
                },
                '.Input:focus': {
                    boxShadow: '0 0 0 0.25rem rgba(23,37,63,.25)'
                }
            }
        };
    }

    static mountStripeElements(paymentOption, clientSecret, billingDetails, callback) {
        const appearance = PaymentMethod.getStripeAppearance();
        const elements = PaymentMethod.stripe.elements({
            clientSecret, 
            appearance
        });
        let defaultValues = {};
        if (billingDetails != undefined) {
            jQuery.extend(defaultValues, { billingDetails });
        }
        const paymentElement = elements.create('payment', {
            defaultValues
        });
        paymentElement.on('ready', function() {
            if (callback != undefined)
                callback();
        });
        switch (paymentOption) {
            case PAYMENT_METHOD_STRIPE:
                paymentElement.mount('#stripeCardPaymentElement');
                break;
            case PAYMENT_METHOD_KLARNA:
                paymentElement.mount('#stripeKlarnaPaymentElement');
                break;
        }
        PaymentMethod.stripeElements[paymentOption] = elements;
    }

    static stripeFormSubmit(paymentOption) {
        UI.buttonStartLoading(PaymentMethod.submitBtn, Lang.General.btnProcessing);
        App.post(BaseURL + '/shop/checkout/payment', PaymentMethod.formSelector, function(resp) {
            if (resp.response) {
                // Confirm the payment intent, on successful confirmation the sdk redirects to the return_url
                PaymentMethod.stripe.confirmPayment({ 
                    elements: PaymentMethod.stripeElements[paymentOption], 
                    confirmParams: {
                        return_url: SiteURL + '/shop/checkout/review'
                    }
                })
                .then(function(result) {
                    if (result.error) {
                        UI.buttonStopLoading(PaymentMethod.submitBtn);
                        UI.error(result.error.message);
                    }
                });
            } else {
                UI.buttonStopLoading(PaymentMethod.submitBtn);
                UI.handleJsonResponse(resp);
            }
        });
    }
};