"use strict";

class Wallet {
    static networkChainId = '0x1';
    static receiveWalletAddress = undefined;
    static isWalletConnected = false;
    static connectionType = undefined;
    static walletAddress = undefined;
    static web3Modal = undefined;

    static async Initialize(networkChainId, receiveWalletAddress) {
        if (typeof networkChainId == 'string' && networkChainId.substring(0, 2) == '0x') {
            networkChainId = parseInt(networkChainId, 16);
        } else if (typeof networkChainId == 'string') {
            networkChainId = parseInt(networkChainId);
        }
        Wallet.networkChainId = networkChainId;
        Wallet.receiveWalletAddress = receiveWalletAddress;
        Wallet.syncLocalState();
        Wallet.hooks();

        if (Wallet.isWalletConnected && Wallet.connectionType != undefined) {
            if (Wallet.connectionType == WALLET_CONNECTION_TYPE_METAMASK) {
                if (window.ethereum != undefined) {
                    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                    if (accounts && accounts.length > 0) {
                        Wallet.handleAccountsChanged(accounts[0]);
                    } else {
                        Wallet.setConnected(false, undefined);
                    }
                }
            }
        }

        // Hook accounts changed event for metamask
        if (window.ethereum != undefined) {
            window.ethereum.on('accountsChanged', function(accounts) {
                if (accounts && accounts.length > 0) {
                    Wallet.handleAccountsChanged(accounts[0]);
                } else {
                    Wallet.handleAccountsChanged(null);
                }
            });
        }
    }

    static loadWeb3() {
        return new Promise((resolve, reject) => {
            // Load web3.js
            App.cachedScript('https://www.unpkg.com/web3@4.0.2/dist/web3.min.js').then(() => {
                window.web3 = new Web3(window.ethereum);
                resolve();
            })
            .catch((error) => {
                reject(error);
            })
        });
    }

    static handleAccountsChanged(address) {
        // Store wallet address
        Wallet.walletAddress = address;

        // Handle disconnection
        if (address == null) {
            Wallet.setConnected(false, undefined);
        }
        
        if (Wallet.walletAddress == null) {
            if ($('#walletConnectedPane').length > 0) {
                $('#walletConnectedPane').hide();
            }
            if ($('#walletConnectBtn').length > 0) {
                $('#walletConnectBtn').show();
            }
        } else {
            if ($('#walletConnectBtn').length > 0) {
                $('#walletConnectBtn').hide();
            }
            if ($('#walletConnectedPane').length > 0) {
                $('#walletConnectedPane').show();
            }
            if ($('.wallet-address-text').length > 0) {
                $('.wallet-address-text').html(Wallet.shortenAddress(Wallet.walletAddress));
            }
        }
    }

    static hooks() {
        if ($('#connectMetamaskBtn').length > 0) {
            $('#connectMetamaskBtn').on('click', function(e) {
                try {
                    Wallet.connectMetamask();
                }
                catch (err) {
                    UI.error(err.message);
                }
                $('#walletConnectBtn .dropdown-toggle').dropdown('toggle');
                return false;
            });
        }
        if ($('#connectWalletConnectBtn').length > 0) {
            $('#connectWalletConnectBtn').on('click', function(e) {
                try {
                    Wallet.connectWalletConnect();
                }
                catch (err) {
                    UI.error(err.message);
                }
                $('#walletConnectBtn .dropdown-toggle').dropdown('toggle');
                return false;
            });
        }
    }

    static storeLocalState() {
        localStorage.setItem('walletState', JSON.stringify({
            isWalletConnected: Wallet.isWalletConnected,
            connectionType: Wallet.connectionType,
        }));
    }

    static syncLocalState() {
        const jsonString = localStorage.getItem('walletState');

        if (jsonString) {
            const data = JSON.parse(jsonString);

            Wallet.isWalletConnected = data.isWalletConnected;
            Wallet.connectionType = data.connectionType;
        }
    }

    static setConnected(state, type) {
        Wallet.isWalletConnected = state;
        Wallet.connectionType = type;
        Wallet.storeLocalState();
    }

    static isConnected() {
        return Wallet.isWalletConnected && Wallet.walletAddress != undefined && Wallet.walletAddress != null;
    }

    static isMetamaskConnected() {
        return Wallet.isConnected() && window.ethereum && window.ethereum.isMetaMask && !window.ethereum.isBraveWallet;
    }

    static async connectWalletForPayment(paymentMethod) {
        if (paymentMethod == PAYMENT_METHOD_METAMASK) {
            return Wallet.connectMetamask();
        }
        else if (paymentMethod == PAYMENT_METHOD_WALLETCONNECT) {
            return Wallet.connectWalletConnect();
        }

        throw new Error('Unhandled payment method wallet.');
    }

    static async connectMetamask() {
        if (Wallet.isMetamaskConnected()) {
            return true;
        }

        if (window.ethereum == undefined || !window.ethereum.isMetaMask || window.ethereum.isBraveWallet) {
            throw new Error(Lang.Wallet.metamaskNotInstalled);
        }

        try {
            await window.ethereum.request({ method: "eth_requestAccounts" });
        } catch (err) {
            if (err.code === 4001) {
                throw new Error(Lang.Wallet.pleaseConnetMetamask);
            } else {
                throw err;
            }
        }

        Wallet.setConnected(true, WALLET_CONNECTION_TYPE_METAMASK);

        return true;
    }

    static async connectWalletConnect() {
        if (Wallet.isConnected() && Wallet.connectionType == WALLET_CONNECTION_TYPE_WALLETCONNECT) {
            return true;
        }

        if (Wallet.web3Modal == undefined) {
            throw new Error('Wallet connect not loaded.');
        }

        let account = await Wallet.web3Modal.connect();

        Wallet.setConnected(true, WALLET_CONNECTION_TYPE_WALLETCONNECT);

        console.log(account);

        return true;
    }

    static isExpectedNetwork() {
        let networkId = 0;

        if (Wallet.isWalletConnected) {
            if (window.ethereum != undefined) {
                networkId = parseInt(window.ethereum.networkVersion);
            } else if (Wallet.web3Modal != undefined) {
                networkId = Wallet.web3Modal.getNetwork().id;
            }
        }

        return networkId == Wallet.networkChainId;
    }

    static async switchToMainnetNetwork() {
        if (Wallet.isExpectedNetwork()) {
            return true;
        }

        if (Wallet.connectionType == WALLET_CONNECTION_TYPE_METAMASK) {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [ { chainId: `0x${Wallet.toHex(Wallet.networkChainId)}` } ],
            });
        } else if (Wallet.connectionType == WALLET_CONNECTION_TYPE_WALLETCONNECT) {
            await Wallet.web3Modal.switchNetwork(Wallet.networkChainId);
        }

        return true;
    }

    static async pay(amountWei) {
        if (!Wallet.isConnected()) {
            throw new Error('No wallet connected!');
        }

        let txHash = null;

        if (Wallet.connectionType == WALLET_CONNECTION_TYPE_METAMASK) {
            try {
                txHash = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: Wallet.walletAddress,
                            to: Wallet.receiveWalletAddress,
                            value: Wallet.toHex(amountWei),
                        },
                    ],
                });
            } catch (err) {
                if (err.code === 4001) {
                    return false;
                }
                throw err;
            }
        } else if (Wallet.connectionType == WALLET_CONNECTION_TYPE_WALLETCONNECT) {
            try {
                txHash = await Wallet.web3Modal.sendTransaction({
                    from: Wallet.walletAddress,
                    to: Wallet.receiveWalletAddress,
                    value: BigInt(amountWei),
                });
            } catch (err) {
                throw err;
            }
        }

        return txHash;
    }

    static toHex(value) {
        return parseInt(value).toString(16);
    }

    static shortenAddress(address) {
        return address.substring(0, 6) + '...' + address.slice(-4)
    }
}