"use strict";
class Template {
    static Initialize() {
        this.InitTemplate();
        this.InitForms();
        this.InitCarousels();
        this.InitCountrySelectInputs();
        this.InitTelInputs();
    }
    static InitTemplate() {
        $('.hover-dropdown-toggle').parent().on('mouseenter', function() {
            $(this).find('.hover-dropdown-toggle').attr('aria-expanded', 'true');
            $(this).addClass('show');
            $(this).find('.dropdown-menu').addClass('show');
        });
        $('.hover-dropdown-toggle').parent().on('mouseleave', function() {
            $(this).find('.hover-dropdown-toggle').attr('aria-expanded', 'false');
            $(this).removeClass('show');
            $(this).find('.dropdown-menu').removeClass('show');
        });
        $('body').on('click', '[data-dismiss="mm"]', (event) => {
            const mm = $(this).closest('.modal-message');
            if (mm && mm.length > 0) {
                mm.fadeOut('fast');
            }
        });
        $('#btnNotificationBannerVisit').on('click', (event) => {
            App.post(BaseURL + '/banner-dismiss', {}, (res) => {
                if (res.response) {
                    $('.notification-banner').fadeOut('fast');
                    window.location = res.location;
                }
            });
        });
        $('#btnNotificationBannerDismiss').on('click', (event) => {
            App.post(BaseURL + '/banner-dismiss', {}, (res) => {
                if (res.response) {
                    $('.notification-banner').fadeOut('fast');
                    // Without redirect
                }
            });
        });
        $('[data-toggle="tooltip"]').tooltip();
        // Language menu
        $('[data-set-lang]').on('click', function (event) {
            const lang = $(this).attr('data-set-lang');
            App.post(BaseURL + '/lang', { locale: lang, currentUrl: window.location }, (resp) => {
                if (resp.response) {
                    $('[data-set-lang]').removeClass('active');
                    $(this).addClass('active');
                    window.location = resp.redirect;
                }
            });
            return false;
        });
        $('.btn-copy').each(function (i, btn) {
            $(btn).attr('data-toggle', 'tooltip');
            $(btn).attr('data-placement', 'bottom');
            $(btn).attr('title', 'Copy to clipboard');
            $(btn).tooltip();
            $(btn).on('hidden.bs.tooltip', function () {
                const tooltip = bootstrap.Tooltip.getInstance(btn);
                tooltip === null || tooltip === void 0 ? void 0 : tooltip.setContent({ '.tooltip-inner': 'Copy to clipboard' });
            });
        });
        $('.btn-copy').on('click', (event) => {
            const targetSelector = $(this).attr('data-clipboard-target');
            if (targetSelector != undefined) {
                const text = $(targetSelector).val();
                const btn = $(this);
                const tooltip = bootstrap.Tooltip.getInstance(btn[0]);
                Template.copyTextToClipboard(text, function () {
                    tooltip === null || tooltip === void 0 ? void 0 : tooltip.setContent({ '.tooltip-inner': 'Copied!' });
                }, function (err) {
                    tooltip === null || tooltip === void 0 ? void 0 : tooltip.setContent({ '.tooltip-inner': 'Whoops, not copied!' });
                });
            }
            return false;
        });
    }
    static InitForms() {
        $(".form-group-file input[type='file']").on('change', (event) => {
            let fileName = $(this).val();
            if (fileName != undefined) {
                fileName = fileName.split('\\').pop();
                $(this).parent('.form-group-file').addClass('file-selected');
                $(this).parent('.form-group-file').find('.selected-file-name').html(fileName !== null && fileName !== void 0 ? fileName : '');
            }
        });
        $(".password-visibility .btn-toggle").on('click', (event) => {
            let cont = $(this).closest('.password-visibility');
            let input = cont.find('input');
            let icon = cont.find('i');
            if (input.attr("type") == "text") {
                input.attr('type', 'password');
                icon.addClass("fa-eye-slash");
                icon.removeClass("fa-eye");
            }
            else if (input.attr("type") == "password") {
                input.attr('type', 'text');
                icon.removeClass("fa-eye-slash");
                icon.addClass("fa-eye");
            }
            event.preventDefault();
            return false;
        });
        $(".code-field").on('keypress', (event) => {
            var charCode = (event.which) ? event.which : event.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57))
                return false;
            return true;
        });
    }
    static InitCarousels() {
        let items = document.querySelectorAll('.carousel-multi .carousel-item');
        items.forEach((el) => {
            const minPerSlide = 3;
            let next = el.nextElementSibling;
            for (var i = 1; i < minPerSlide; i++) {
                if (!next) {
                    // wrap carousel by using first child
                    next = items[0];
                }
                if (next.firstElementChild) {
                    let cloned = next.firstElementChild.cloneNode(true);
                    el.append(cloned);
                }
                next = next.nextElementSibling;
            }
        });
        if ($('.owl-carousel').length > 0) {
            App.cachedScript(BaseURL + '/assets/frontend/js/libs/owl.carousel.min.js').then(async function() {
                const childCount = $('.owl-carousel').children().length;
                $('.owl-carousel').owlCarousel({
                    loop: true,
                    margin: 0,
                    navText: [ "<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>" ],
                    responsiveClass: true,
                    responsive: {
                        0:{
                            loop: true,
                            items: 1,
                            nav: false,
                        },
                        480:{
                            loop: (childCount >= 2),
                            items: 2,
                            nav: false,
                        },
                        768:{
                            loop: (childCount >= 3),
                            items: 3,
                            nav: false,
                        },
                        1100:{
                            loop: (childCount >= 4),
                            items: 4,
                            nav: true,
                        },
                    }
                });
            });
        }
    }
    static InitCountrySelectInputs() {
        if ($('.country-select').length > 0) {
            App.cachedScript(BaseURL + '/assets/frontend/js/libs/countrySelect.min.js').then(async function() {
                $('.country-select').countrySelect({
                    defaultCountry: 'auto',
                    preferredCountries: ['at', 'gb', 'us'],
                    geoIpLookup: function (success) {
                        $.ajax({
                            url: BaseURL + '/getCountryCode',
                            success: function (resp) {
                                var countryCode = (resp && resp.country_code2) ? resp.country_code2 : 'AT';
                                success(countryCode);
                            },
                            cache: false
                        });
                    },
                });
            });
        }
    }
    static InitTelInputs() {
        const inputs = document.querySelectorAll('.tel-input');

        if (inputs.length > 0) {
            if (window.intlTelInput == undefined) {
                App.cachedScript(BaseURL + '/assets/frontend/js/libs/intlTelInput.min.js').then(async function() {
                    $(inputs).each(function (i, input) {
                        Template.InitTelInput(input);
                    });
                });
            } else {
                $(inputs).each(function (i, input) {
                    Template.InitTelInput(input);
                });
            }
        }
    }
    static InitTelInput(input) {
        if ($(input).data('iti') != undefined) {
            return;
        }
        const iti = window.intlTelInput(input, {
            //nationalMode: true,
            preferredCountries: ['at', 'gb', 'us'],
            utilsScript: BaseURL + '/assets/frontend/js/libs/intlTelInput.utils.min.js',
            initialCountry: 'auto',
            geoIpLookup: function (success) {
                $.ajax({
                    url: BaseURL + '/getCountryCode',
                    success: function (resp) {
                        var countryCode = (resp && resp.country_code2) ? resp.country_code2 : 'AT';
                        success(countryCode);
                    },
                    cache: false
                });
            },
            //separateDialCode: true
        });
        $(input).data('iti', iti);
        // Prepare the fullphone field
        let fullphoneInput = $('<input type="hidden" name="fullphone">');
        $(input).after(fullphoneInput);
        if ($(input).val().length > 0) {
            fullphoneInput.val($(input).val());
        }
        // here, the index maps to the error code returned from getValidationError - see readme
        const errorMap = [
            'The phone number is invalid.',
            'The country code is invalid.',
            'The phone number is too short.',
            'The phone number is too long.',
            'The phone number is invalid.',
            'The phone number format is invalid.' //INVALID_LENGTH
        ];
        $(input).on('resetError', function () {
            UI.clearFieldError(input);
        });
        $(input).on('fillFullphone', function () {
            if (iti.isValidNumber()) {
                fullphoneInput.val(iti.getNumber());
            } else {
                fullphoneInput.val(null);
            }
        });
        // on blur: validate
        $(input).on('blur', function () {
            $(input).trigger('resetError');
            if ($(input).val()) {
                if (!$(input).data('iti').isValidNumber()) {
                    const errorCode = $(input).data('iti').getValidationError();
                    UI.fieldError((errorCode == -99 ? errorMap[0] : errorMap[errorCode]), input);
                }
            }
        });
        // on keyup / change flag: reset
        $(input).on('change', function () {
            $(input).trigger('resetError');
        });
        $(input).on('keyup', function () {
            $(input).trigger('resetError');
            $(input).trigger('fillFullphone');
        });
        $(input).on("countrychange", function () {
            $(input).trigger('resetError');
            $(input).trigger('fillFullphone');
        });
    }
    static fallbackCopyTextToClipboard(text, callback, onError) {
        let textArea = document.createElement("textarea");
        textArea.value = text;
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            const successful = document.execCommand('copy');
            if (callback)
                callback(successful);
        }
        catch (err) {
            if (onError)
                onError(err);
        }
        document.body.removeChild(textArea);
    }
    static copyTextToClipboard(text, callback, onError) {
        if (!navigator.clipboard) {
            Template.fallbackCopyTextToClipboard(text, callback, onError);
            return;
        }
        navigator.clipboard.writeText(text).then(callback, onError);
    }
}
;
(function () {
    Template.Initialize();
})();