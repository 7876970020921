"use strict";

class Payment {
    static paymentMethod = PAYMENT_METHOD_NONE;
    static formSelector = '#paymentFinalizeForm';
    static submitBtn = null;
    static stripe = null;
    static paymentCountry = null;

    static Initialize(paymentMethod) {
        Payment.paymentMethod = paymentMethod;

        const form = $(Payment.formSelector);
        Payment.submitBtn = form.find('[type="submit"]');

        $('#termsAgreement').on('change', function(event) {
            const state = $(this).is(':checked');
            Payment.submitBtn.prop('disabled', !state);
        });

        form.on('submit', function(event) {
            event.preventDefault();
            const termsCheckbox = $('#termsAgreement');
            if (termsCheckbox.length == 0) {
                return false;
            } else {
                if (!termsCheckbox.is(':checked')) {
                    return false;
                }
            }
            // Crypto payment form submission
            if (Payment.paymentMethod == PAYMENT_METHOD_METAMASK || Payment.paymentMethod == PAYMENT_METHOD_WALLETCONNECT) {
                UI.buttonStartLoading(Payment.submitBtn, Lang.General.btnProcessing);
                $.get(BaseURL + '/shop/checkout/get-payment-amount', function(resp) {
                    if (resp.response) {
                        if ($('#totalPriceInCrypto').length > 0) {
                            $('#totalPriceInCrypto').html(resp.amount + ' ' + resp.currency);
                        }
                        Payment.pay(resp.amountWei);
                    } else {
                        UI.buttonStopLoading(Payment.submitBtn);
                        UI.handleJsonResponse(resp);
                    }
                });
                return false;
            } else if (Payment.paymentMethod == PAYMENT_METHOD_SOFORT) {
                Payment.submitStripeSofort();
                return false;
            }
            // Default form submission
            UI.buttonStartLoading(Payment.submitBtn, Lang.General.btnProcessing);
            App.post(BaseURL + '/shop/checkout/pay', Payment.formSelector, function(resp) {
                UI.buttonStopLoading(Payment.submitBtn);
                UI.handleJsonResponse(resp);
            });
            return false;
        });
    }

    static async pay(amountWei) {
        if (!Wallet.isConnected()) {
            try {
                await Wallet.connectWalletForPayment(Payment.paymentMethod);
            } catch (error) {
                UI.error(error.message);
                UI.buttonStopLoading(Payment.submitBtn);
                return false;
            }
        }

        if (!Wallet.isExpectedNetwork()) {
            try {
                await Wallet.switchToMainnetNetwork();
            } catch (err) {
                throw new Error(Lang.Wallet.failedToSwitchNetworks);
            }
        }

        let txHash;

        try {
            txHash = await Wallet.pay(amountWei);
        } catch (error) {
            UI.error(error.message);
            UI.buttonStopLoading(Payment.submitBtn);
            return false;
        }

        // Check for user cancellation
        if (!txHash) {
            UI.buttonStopLoading(Payment.submitBtn);
            return false;
        }
        
        // Submit the transaction to the backend
        App.post(BaseURL + '/shop/checkout/pay-eth', { txHash }, function(resp) {
            UI.buttonStopLoading(Payment.submitBtn);
            UI.handleJsonResponse(resp);
        });

        return true;
    }

    static ensureStripeLoaded(publicKey, locale, callback) {
        if (Payment.stripe != null) {
            if (callback != undefined)
                callback();
        } else {
            App.cachedScript('https://js.stripe.com/v3/').then(function() {
                Payment.stripe = Stripe(publicKey, { locale });
                if (callback != undefined)
                    callback();
            });
        }
    }

    static submitStripeSofort() {
        UI.buttonStartLoading(Payment.submitBtn, Lang.General.btnProcessing);
        App.post(BaseURL + '/shop/checkout/stripe', { paymentMethod: PAYMENT_METHOD_SOFORT }, function(resp) {
            if (resp.response) {
                Payment.ensureStripeLoaded(resp.publicKey, resp.locale, function() {
                    Payment.stripe.confirmSofortPayment(resp.clientSecret, { 
                        payment_method: {
                            sofort: {
                                country: Payment.paymentCountry
                            }
                        },
                        return_url: SiteURL + '/shop/checkout/payment-pending'
                    })
                    .then(function(result) {
                        if (result.error) {
                            UI.buttonStopLoading(Payment.submitBtn);
                            UI.error(result.error.message);
                        }
                    });
                });
            } else {
                UI.buttonStopLoading(Payment.submitBtn);
                UI.handleJsonResponse(resp);
            }
        });
    }
}