"use strict";
class FormValidation {
    /**
     * Initialize form validation on the given DOMElement.
     */
    static Initialize(form, options) {
        const defaults = {
            url: '',
            submitOnSuccess: true,
            usingRecaptcha: false,
            successCallback: function (resp) { },
            onSerialize: function (form) {
                return form;
            }
        };
        const settings = $.extend(defaults, options);
        $(form).on('submit', function (event) {
            const formElement = $(this);
            //const btn = thisform.find('[type="submit"]');
            //App.ButtonStartLoading(btn);
            let formData = App.getFormData(formElement);
            var postData = settings.onSerialize(formData);
            App.post(settings.url, postData, function (resp) {
                if (resp.response) {
                    if (typeof settings.successCallback == 'function') {
                        settings.successCallback(resp);
                    }
                    if (settings.submitOnSuccess) {
                        formElement.off('submit');
                        /*if (settings.usingRecaptcha && typeof grecaptcha != 'undefined') {
                            if (grecaptcha.getResponse()) {
                                thisform.submit();
                            } else {
                                grecaptcha.execute();
                                return;
                            }
                        } else {*/
                        formElement.trigger('submit');
                        //}
                    }
                }
                else {
                    if (typeof resp.errors != 'undefined') {
                        FormValidation.ProcessErrors(resp.errors, formElement);
                    }
                    else if (typeof resp.error != 'undefined') {
                        //App.ErrorModal(resp.heading, resp.error);
                        console.log(resp.heading, resp.error);
                    }
                    /*if (typeof grecaptcha != 'undefined') {
                        grecaptcha.reset();
                    }*/
                }
                //App.ButtonStopLoading(btn);
            });
            event.preventDefault();
            return false;
        });
    }
    static ProcessErrors(errors, form) {
        var isFirst = true;
        for (var key in errors) {
            if (!errors.hasOwnProperty(key))
                continue;
            FormValidation.ProcessError(key, errors[key], form, isFirst);
            isFirst = false;
        }
    }
    static ProcessError(name, error, form, scrollTo) {
        var _a;
        const cont = form || $('body');
        const field = cont.find('[name="' + name + '"]');
        if (field == undefined || field.length == 0) {
            return;
        }
        const wrapper = FormValidation.GetFieldContainer(field);
        field.addClass('is-invalid');
        field.on('change', function () {
            wrapper.removeClass('is-invalid');
            $('.invalid-feedback', wrapper).remove();
            field.removeClass('is-invalid');
            field.off('change');
        });
        wrapper.addClass('is-invalid');
        $('.invalid-feedback', wrapper).remove();
        wrapper.append('<p class="invalid-feedback">' + error + '</p>');
        if (scrollTo && !App.isInViewport(wrapper[0])) {
            $('html, body').animate({
                scrollTop: (_a = wrapper.offset()) === null || _a === void 0 ? void 0 : _a.top
            }, 300);
        }
    }
    static GetFieldContainer(field) {
        if (field.closest('.form-group').length > 0) {
            return field.closest('.form-group');
        }
        else if (field.closest('.form-row').length > 0) {
            return field.closest('.form-row');
        }
        return field.parent();
    }
}