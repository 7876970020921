"use strict";

class BlogBrowse {
    static filters = {
        page: 1,
        search: null,
        categories: []
    };

    static Initialize() {
        $('#searchInput').on('change', function(e) {
            BlogBrowse.filters.search = $(e.target).val();
            return true;
        });
        $('#searchForm').on('submit', function(e) {
            BlogBrowse.reload(1);
            e.preventDefault();
            return false;
        });
        $('.tag-filter').on('click', function(e) {
            let categories = [];
            $('.tag-filter').each((i, btn) => {
                if ($(btn).hasClass('active')) {
                    categories.push(parseInt($(btn).attr('data-category')));
                }
            });
            BlogBrowse.filters.categories = categories;
            BlogBrowse.reload(1);
        });
        $('body').on('click', '.loadmore-btn', function (e) {
            const btn = $(e.target);
            BlogBrowse.loadMore(btn);
            return false;
        });
    }

    static reload(page) {
        UI.startLoadingOverlay();
        // Load results
        $.get(SiteURL + '/blog/load', BlogBrowse.filters, function(resp) {
            if (resp.response) {
                if (!resp.hasMore) {
                    $('.loadmore-btn').hide();
                } else {
                    $('.loadmore-btn').show();
                }
                BlogBrowse.replaceResults(resp.resultsHtml);
                BlogBrowse.filters.page = page;
            } else {
                UI.handleJsonResponse(resp);
            }
        });
    }

    static loadMore(btn) {
        if (typeof btn != 'undefined') {
            UI.buttonStartLoading(btn);
        }

        // Copy filters to modify the page
        let filters = { ...BlogBrowse.filters };
        filters.page += 1;

        // Load results
        $.get(BaseURL + '/blog/load', filters, function(resp) {
            if (resp.response) {
                BlogBrowse.filters.page = filters.page;
                BlogBrowse.appendResults(resp.resultsHtml);
                if (!resp.hasMore) {
                    $('.loadmore-btn').fadeOut('fast');
                }
            } else {
                UI.handleJsonResponse(resp);
            }
            if (typeof btn != 'undefined') {
                UI.buttonStopLoading(btn);
            }
        });
    }

    static replaceResults(resultsHtml) {
        const cont = $('#articleResults');
        let results = $(resultsHtml);
        results.find('.article').each(function(i, e) {
            $(e).hide();
        });
        cont.html('');
        cont.append(results);
        UI.stopLoadingOverlay();
        cont.find('.article').each(function(i, e) {
            $(e).fadeIn('fast');
        });
    }

    static appendResults(resultsHtml) {
        const cont = $('#articleResults');
        let results = $(resultsHtml);
        results.find('.article').each(function(i, e) {
            $(e).hide();
        });
        cont.append(results);
        cont.find('.article').each(function(i, e) {
            $(e).fadeIn('fast');
        });
    }
}